import * as React from "react";

import LoaderSVG from "./loader";
import Background from "./backgroud-transparent";

const Loader = () => (
  <Background>
    <LoaderSVG />
  </Background>
);

export default Loader;
