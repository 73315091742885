import * as React from "react";

export default function SearchIcon() {
  return (
   <svg xmlns="http://www.w3.org/2000/svg" width="15.311" height="15.311" viewBox="0 0 15.311 15.311">
  <g id="search" transform="translate(-2.25 -2.25)">
    <circle id="Ellipse_1841" data-name="Ellipse 1841" cx="6.214" cy="6.214" r="6.214" transform="translate(3 3)" fill="none" stroke="#727272" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    <line id="Line_2859" data-name="Line 2859" x1="2.832" y1="2.832" transform="translate(13.668 13.668)" fill="none" stroke="#727272" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  </g>
</svg>

  );
}

