import * as React from "react";

export default function TotalServiceChargesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g
        id="Group_6014"
        data-name="Group 6014"
        transform="translate(-637 -145)"
      >
        <g id="Group_5671" data-name="Group 5671" transform="translate(93 9)">
          <circle
            id="Ellipse_1752"
            data-name="Ellipse 1752"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(544 136)"
            fill="#f2eeff"
          />
        </g>
        <g
          id="Group_6005"
          data-name="Group 6005"
          transform="translate(241.904 64.354)"
        >
          <g
            id="Group_6003"
            data-name="Group 6003"
            transform="translate(414.486 100.441)"
          >
            <g
              id="Group_6002"
              data-name="Group 6002"
              transform="translate(7.889 4.213)"
            >
              <path
                id="Path_22574"
                data-name="Path 22574"
                d="M493.34,170.749h-3.924a1.936,1.936,0,0,0-1.936,1.936v.357a1.936,1.936,0,0,0,1.936,1.936H491.4a1.936,1.936,0,0,1,1.936,1.936v.44a1.936,1.936,0,0,1-1.936,1.936h-3.923"
                transform="translate(-487.481 -168.372)"
                fill="none"
                stroke="#6631f7"
                strokeWidth="1.5"
              />
              <path
                id="Path_22575"
                data-name="Path 22575"
                d="M505.714,155.956V169.25"
                transform="translate(-502.784 -155.956)"
                fill="none"
                stroke="#6631f7"
                strokeWidth="1.5"
              />
            </g>
            <circle
              id="Ellipse_2181"
              data-name="Ellipse 2181"
              cx="10.819"
              cy="10.819"
              r="10.819"
              transform="translate(0 0)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
          </g>
          <g
            id="Group_6004"
            data-name="Group 6004"
            transform="translate(410.407 94.833)"
          >
            <path
              id="Path_22576"
              data-name="Path 22576"
              d="M425.209,106.467a14.782,14.782,0,0,0,0,29.115"
              transform="translate(-412.996 -104.598)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
            <path
              id="Path_22577"
              data-name="Path 22577"
              d="M473.73,94.833l2.914,1.907-2.527,2.4"
              transform="translate(-463.972 -94.833)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
            <path
              id="Path_22578"
              data-name="Path 22578"
              d="M522.42,106.47a14.779,14.779,0,0,1,12.167,13.393"
              transform="translate(-504.838 -104.6)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
            <path
              id="Path_22579"
              data-name="Path 22579"
              d="M522.42,217.713a14.779,14.779,0,0,0,12.167-13.393"
              transform="translate(-504.838 -186.728)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
            <path
              id="Path_22580"
              data-name="Path 22580"
              d="M522.474,276.814l-2.914-1.907,2.527-2.4"
              transform="translate(-502.437 -243.96)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
          </g>
          <circle
            id="Ellipse_2182"
            data-name="Ellipse 2182"
            cx="0.385"
            cy="0.385"
            r="0.385"
            transform="translate(439.771 110.875)"
            fill="#004194"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
