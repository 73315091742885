import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import { RootState } from "&store/store";
import { packagesActions } from "../packages.slice";
import TextWithSelect from "&styled/textWithSelect";
import { Button } from "@mui/material";

type ReduxProps = ConnectedProps<typeof connector>;

type Product = {
    amount: string;
    callbackUrl: string;
    name: string;
    serviceId: string;
    country: string;
    currency: string;
}

const countryCodes = [
   
    {label: "92", value:"92"}, // pk
   
]
const CheckoutLinkComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { checkoutId ,getPackage, generateLink} = props;

  const generateRandomNumber=() => {
    const timestamp = Date.now().toString();
    const randomString = timestamp.substring(timestamp.length - 8);
    const randomNumber = parseInt(randomString, 10);
    return randomNumber;
  }

  const [countryCode, setCountryCode] = useState("92");
  const [msisdn, setMsisdn] = useState("")
  const [error, setError] = useState("")
  const [pkg, setPkg] = useState<Product>()
  const [orderId, setOrderId] = useState(0)

  useEffect(() => {
    (async()=>{
        const {payload} = await getPackage(checkoutId)
        if(!payload?.errors){
            setPkg(payload)
        }
    })()
  }, [checkoutId, countryCode, getPackage]);

  useEffect(()=>{
    const oId = generateRandomNumber();
    setOrderId(oId)
  },[])
  const handleCountryCodeChange = (e) => {
    const val = e.target.value;
    setCountryCode(val);
  }

  const onTextChange = (e) => {
    const val = e.target.value;
    setMsisdn(val);
  }

  const onContinue = async() => {
    if(!msisdn){
        setError("Valid mobile number required");
        return;
    }
    if(msisdn.startsWith("92") || msisdn.startsWith("0")){
      setError("Mobile number shouldn't start with 92 or 0");
      return;
    }
    const regex = /^3\d{9}$/;
    if(!regex.test(msisdn)){
      setError("Invalid mobile number");
      return;
    }
    const payload = {
        msisdn: `0${msisdn}`,
        id: checkoutId,
        serviceId: pkg?.serviceId,
        orderId, 
    }
    
    const {payload: data} = await generateLink(payload)
    if(data.link){
        window.location.href = data.link
    }
  }

  let contents:any = ""
if(!pkg){
   contents = <Box>This is not a valid link. Please contact your account manager</Box>
}else{
    contents =  <Box
    sx={{
      marginY: "1rem",
      borderTop: "4px solid #6631F7",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    }}
  >
    <Card>
      <CardContent>
       <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{
                display:"flex", 
                flexDirection:"column",
                gap:2,
                alignItems:"center", 
                width:"90%", 
                height:"auto",
                borderRadius:"5px",
                padding: "1rem", 
                boxShadow:"7px 9px 28px -4px rgba(59,56,204,0.5)"
                }}>
                <Box sx={{fontWeight:"bold"}}>Review your order</Box>
                {/*Price Box*/}
                <Box sx={{display:"flex", gap:0.5, alignItems:"baseline"}}>
                    <Box>{pkg.currency?.toUpperCase() || "PKR"}</Box>
                    <Box sx={{fontSize:"2rem", fontWeight:"300"}}>{pkg.amount}</Box>
                </Box>
                {/*Order ID Box*/}
                <Box sx={{display:"flex", gap:2}}>
                    <Box>Order ID :</Box>
                    <Box sx={{color:"#6631F7"}}>{orderId}</Box>
                </Box>
                {/*MSISDN Input Instructions*/}
                <Box>Kindly enter your valid mobile number to continue</Box>
                {/*MSISDN Input*/}
                <TextWithSelect 
                    countryCode={countryCode}
                    countryCodes={countryCodes}
                    handleCountryCodeChange={handleCountryCodeChange}
                    phoneNbr={msisdn}
                    onTextChange={onTextChange}
                    hasError={error ? true : false}
                    errorMessage={error}
                />
                {/*CTA*/}
                <Box sx={{width:"100%"}}>
                    <Button sx={{
                        backgroundColor:"#6631F7",
                        color: "#ffffff",
                        border:"1px solid transparent",
                        "&:hover":{
                            backgroundColor:"#ffffff",
                            color: "#6631F7",
                            border:"1px solid #6631F7"
                        }
                    }} fullWidth onClick={onContinue}>Continue</Button>
                </Box>
            </Box>
        </Grid>
       </Grid>
      </CardContent>
    </Card>
  </Box>
}
  return (
    <> 
     {contents}
     
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
    checkoutId: ownProps.match.params.id,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  generateLink: packagesActions.generateLink,
  getPackage: packagesActions.getPackage
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CheckoutLinkComponentRedux = connector(CheckoutLinkComponent);

export { CheckoutLinkComponentRedux as CheckoutLinkComponent };
