import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { usersActions } from "&features/users/users.slice";
import { carriersActions } from "&features/carriers/carriers.slice";
import { merchantsActions } from "&features/merchants/merchants.slice";
import { rolesActions } from "&features/roles/roles.slice";
import SelectComponent from "&styled/form/select";
import { SubmitButton } from "&styled/button/button.component";
import {
  TextInput,
  PasswordInput,
} from "&styled/textField/textField.component";
import { getCurrentUser } from "&config/getCurrentUser";
import SnackbarComponent from "&styled/snackBar/snackbar.component";

type ReduxProps = ConnectedProps<typeof connector>;

const UserFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    getUser,
    addUser,
    editUser,
    getMerchants,
    getCarriers,
    getRoleByUser,
    token,
    userId,
  } = props;
  //complete collection of roles
  const [roles, setRoles] = useState<any[]>([]);
  //complete collection of merchants
  const [carrierOrMerchants, setCarrierOrMerchants] = useState<any[]>([]);

  // Since custom select accepts array of object in [{label:"",value:""}]
  // so we formatted Carriers or Merchants in this structure based on userTypes
  // userTypes => Admin, Operator, Merchant
  const [userType, setUserType] = useState<any[]>([]);
  const [userTypes, setUserTypes] = useState<any[]>([
    { label: "Admin", value: "Admin" },
    { label: "Operator", value: "Operator" },
    { label: "Merchant", value: "Merchant" },
  ]);
  
  // Formatted role array ([{label:"",value:""}])
  const [role, setRole] = useState<any[]>([]);
  // server side error
  const [error, setError] = useState<string>("");
  const [data, setData] = useState({
    _id: "",
    name: "",
    userName: "",
    password: "",
    email: "",
    userType: "",
    userTypeName: "",
    userTypeId: "",
    roleId: "",
  });

  const user = getCurrentUser(token);

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short name.")
      .max(50, "Too long name")
      .required("Please provide valid full name"),
    userName: Yup.string()
      .min(2, "Too short userName.")
      .max(50, "Too long userName")
      .required("Please provide valid user name"),
    password: Yup.string().when("_id", {
      is: "",
      then: Yup.string()
        .min(5)
        .max(15)
        .required("Please provide valid password"),
    }),
    email: Yup.string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Please provide currency"),
    roleId: Yup.string().required("Please provide valid role"),
  });



  const fetchUser = useCallback(
    async (id) => {
      try {
        const { payload: user } = await getUser(id);
        setData((prevState) => ({
          ...prevState,

          _id: user._id,
          name: user.name,
          email: user.email,
          userName: user.userName,
          userType: user.userType,
          userTypeName: user.userTypeName,
          userTypeId: user.userTypeId,
          roleId: user.role._id,
        }));

        if(user.userType === "Merchant"){
          const { payload } = await getMerchants();
          formatUserTypes(payload);
          setCarrierOrMerchants(payload);
        }else if(user.userType === "Operator"){
          const { payload } = await getCarriers();
          formatUserTypes(payload);
          setCarrierOrMerchants(payload);
        }
 
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getUser,getMerchants,getCarriers]
  );
  const fetchRolesByUser = useCallback(async () => {
    try {
      const { payload } = await getRoleByUser();
      const formattedRoles: any[] = [];
      payload.forEach((r) => {
        formattedRoles.push({ label: r.name, value: r._id });
      });
      setRole(formattedRoles);
      setRoles(payload);
    } catch (ex) {
      console.log(ex);
      alert("Something went wrong");
    }
  }, [getRoleByUser]);

  useEffect(() => {
    const id = userId;
    fetchRolesByUser();
    if (id === "new") return;
    fetchUser(id);
  }, [userId, fetchUser, fetchRolesByUser]);

  const formatUserTypes = (arr) => {
    const formatted: any[] = [];
    arr.forEach((item) => {
      formatted.push({ label: item.name, value: item._id });
    });
    setUserType(formatted);
  };
  // user type change
  const handleUserType = async (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("userType", val);
    if (val === "Admin") return;
    if (val === "Operator") {
      const { payload } = await getCarriers();
      formatUserTypes(payload);
      setCarrierOrMerchants(payload);
    }
    if (val === "Merchant") {
      const { payload } = await getMerchants();
      formatUserTypes(payload);
      setCarrierOrMerchants(payload);
    }
  
  };

  const handleSubmit = async (vals) => {
   
    // Get Usertype Name (This is the name of Merchant/Carrier)
    let filteredUserType = carrierOrMerchants.filter(
      (u) => u._id === vals.userTypeId
    );
 
    let userTypeName = "";
    if (filteredUserType.length > 0) {
      userTypeName = filteredUserType[0].name;
      // Assign Usertype name to data object
      vals["userTypeName"] = userTypeName;
    } else {
      vals["userTypeId"] = user?.userTypeId as string;
      vals["userType"] = data.userType
        ? vals.userType
        : (user?.userType as string);
    }

    // get role object
    let filteredRole = roles.filter((r) => r._id === vals.roleId);
    vals["role"] = filteredRole[0];
 
    if (data._id) {
      const { payload } = await editUser(vals);
      if (payload.errors) {
        setError(payload.errors[0].message);
      } else {
        history.push("/users");
      }
    } else {
      const { payload } = await addUser(vals);
      if (payload.errors) {
        setError(payload.errors[0].message);
      } else {
        history.push("/users");
      }
    }
  };

  
  return (
    <>
      <PageHeader title="User Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Full Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"User Name"}
                      value={formik.values.userName}
                      handleTextChange={formik.handleChange("userName")}
                      hasError={!!formik.errors.userName}
                      errorMessage={formik.errors.userName as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  {!data._id && (
                    <>
                      <Grid item lg={4} sm={12}>
                        <PasswordInput
                          placeHolder={"Password"}
                          required={false}
                          value={formik.values.password}
                          handleTextChange={formik.handleChange("password")}
                          hasError={!!formik.errors.password}
                          errorMessage={formik.errors.password as string}
                        />
                      </Grid>

                      <Grid item lg={8} sm={12}></Grid>
                    </>
                  )}
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Email"}
                      required={false}
                      value={formik.values.email}
                      handleTextChange={formik.handleChange("email")}
                      hasError={!!formik.errors.email}
                      errorMessage={formik.errors.email as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  {user?.userTypeId === "Walee" && (
                    <>
                      <Grid item lg={4} sm={12}>
                        <SelectComponent
                          value={formik.values.userType}
                          onSelect={(e) => handleUserType(e, formik)}
                          menuItems={userTypes}
                          placeHolder="Select User Type"
                          hasError={!!formik.errors.userType}
                          errorMessage={formik.errors.userType as string}
                        />
                      </Grid>
                      <Grid item lg={8} sm={12}></Grid>
                    </>
                  )}

                  {userType.length > 0 && (
                    <>
                      <Grid item lg={4} sm={12}>
                        <SelectComponent
                          value={formik.values.userTypeId}
                          onSelect={formik.handleChange("userTypeId")}
                          menuItems={userType}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.userTypeId}
                          errorMessage={formik.errors.userTypeId as string}
                        />
                      </Grid>
                      <Grid item lg={8} sm={12}></Grid>
                    </>
                  )}

                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.roleId}
                      onSelect={formik.handleChange("roleId")}
                      menuItems={role}
                      placeHolder="Select Role"
                      hasError={!!formik.errors.roleId}
                      errorMessage={formik.errors.roleId as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
      {error && <SnackbarComponent type="error" message={error} />}
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  userId: ownProps.match.params.userId,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getUser: usersActions.getUser,
  addUser: usersActions.addUser,
  editUser: usersActions.editUser,
  getMerchants: merchantsActions.getMerchants,
  getCarriers: carriersActions.getCarriers,
  getRoleByUser: rolesActions.getRoleByUser,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const UserFormComponentRedux = connector(UserFormComponent);

export { UserFormComponentRedux as UserFormComponent };
