import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 2,
};

export default function DetailModal({ open, handleClose, values }) {
  const {
    merchantName,
    totalAmount,
    currency,
    paymentMethod,
    exchangeRate,
    waleeCommission,
    sbpCharges,
    totalBilledAmount,
    withHoldingCharges,
    serviceSalesTax,
  } = values;

  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              marginY: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ color: "#2e2e2e", fontSize: "20px", fontWeight: "bold" }}
            >
              Transaction Summary
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Divider />
          <Grid container spacing={2} marginY={2}>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Merchant</Box>
            </Grid>
            <Grid item lg={6}>
              {merchantName}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Total Amount</Box>
            </Grid>
            <Grid item lg={6}>
              {totalAmount}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Currency</Box>
            </Grid>
            <Grid item lg={6}>
              {currency}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Payment Method</Box>
            </Grid>
            <Grid item lg={6}>
              {paymentMethod}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Walee Commission</Box>
            </Grid>
            <Grid item lg={6}>
              {waleeCommission} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>SBP Charges</Box>
            </Grid>
            <Grid item lg={6}>
              {sbpCharges} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Service Sales Tax</Box>
            </Grid>
            <Grid item lg={6}>
              {serviceSalesTax} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Withholding Tax</Box>
            </Grid>
            <Grid item lg={6}>
              {withHoldingCharges} %
            </Grid>
            {currency === "USD" && (
              <>
                <Grid item lg={6}>
                  <Box sx={{ fontWeight: "bold" }}>Exchange Rate</Box>
                </Grid>
                <Grid item lg={6}>
                  {exchangeRate}
                </Grid>
              </>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Total Billed Amount</Box>
            </Grid>
            <Grid item lg={6}>
              {pkFormatter.format(totalBilledAmount)}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
