import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { usersActions } from "&features/users/users.slice";

import { SubmitButton } from "&styled/button/button.component";
import { PasswordInput } from "&styled/textField/textField.component";
import { successAlert } from "&config/swalGenerator";
import SnackbarComponent from "&styled/snackBar/snackbar.component";

type ReduxProps = ConnectedProps<typeof connector>;

const ChangePasswordFormComponent = (props: ReduxProps) => {
  const { changePassword } = props;

  const [error, setError] = useState<string>("");
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validation = Yup.object().shape({
    currentPassword: Yup.string()
      .min(2, "Too short password.")
      .max(50, "Too long password")
      .required("Please provide password"),
    newPassword: Yup.string()
      .min(2, "Too short password.")
      .max(50, "Too long password")
      .required("Please provide valid new password"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  });

  useEffect(() => {}, []);

  const handleSubmit = async (vals) => {
    const { payload } = await changePassword(vals);
    if (payload.errors) {
      setError(payload.errors[0].message);
    } else {
      successAlert("Success", "Password updated successfully");
    }
  };

  return (
    <>
      <PageHeader title="Change Password" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={12}>
                    <PasswordInput
                      placeHolder={"Current Password"}
                      value={formik.values.currentPassword}
                      handleTextChange={formik.handleChange("currentPassword")}
                      hasError={!!formik.errors.currentPassword}
                      errorMessage={formik.errors.currentPassword as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <PasswordInput
                      placeHolder={"New Password"}
                      value={formik.values.newPassword}
                      handleTextChange={formik.handleChange("newPassword")}
                      hasError={!!formik.errors.newPassword}
                      errorMessage={formik.errors.newPassword as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <PasswordInput
                      placeHolder={"Confirm Password"}
                      value={formik.values.confirmPassword}
                      handleTextChange={formik.handleChange("confirmPassword")}
                      hasError={!!formik.errors.confirmPassword}
                      errorMessage={formik.errors.confirmPassword as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
      {error && <SnackbarComponent type="error" message={error} />}
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  changePassword: usersActions.changePassword,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ChangePasswordFormComponentRedux = connector(ChangePasswordFormComponent);

export { ChangePasswordFormComponentRedux as ChangePasswordFormComponent };
