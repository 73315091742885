import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TrashIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "&assets/icons/edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import {
  warningAlert,
  successAlert,
  infoAlert,
  errorAlert,
} from "&config/swalGenerator";

import { RootState } from "&store/store";

import { usersActions } from "./users.slice";

import styles from "./users.module.css";

type ReduxProps = ConnectedProps<typeof connector>;

const UsersComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getUsers, editUserStatus } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "userType",
      headerName: "User Type",
      flex:1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex:1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userName",
      headerName: "User Name",
      flex:1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex:1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role",
      headerName: "Role",
      renderCell: ({ row }) => row.role.name,
      flex:1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => renderStatus(row),
      flex:1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex:1, 
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [getUsers]);

  const changeUserStatus = async (user) => {
    let usersClone = [...data];
    let userClone = { ...user };
    let index = usersClone.map((s) => s._id).indexOf(userClone._id);
    if (index !== -1) {
      try {
        let params = { ...userClone, status: usersClone[index].status === "Active" ? "Inactive" : "Active" };

        await editUserStatus(params);

        successAlert("Success", "Service status changed succesfully");
      } catch (ex) {
        console.log(ex);
        errorAlert();
      }
    }
  };

  const renderButtons = (row) => {
    return <Box sx={{ display: "flex", gap: 2 }}>{renderBtnEdit(row)}</Box>;
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/users/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  const renderStatus = (row) => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ece5fe",
          color: row.status === "Active" ? "#6631f7" : "#f44336",
          "&:hover": {
            backgroundColor: "#ece5fe",
          },
        }}
        onClick={() => changeUserStatus(row)}
      >
        {row.status}
      </Button>
    );
  };
  return (
    <>
      <PageHeader title="Users" onAdd={() => history.push("/users/form/new")} addText="Create User"/>
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.users,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getUsers: usersActions.getUsers,
  editUserStatus: usersActions.editUserStatus
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const UsersComponentRedux = connector(UsersComponent);

export { UsersComponentRedux as UsersComponent };
