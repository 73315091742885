import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";

const COLORS = ["#6631F7", "#DBCEFF", "#AB8CFF", "#9772fa", "#7e52f8"];

export default function CustomChart({ data }) {
  //const data = [
  //  { name: "Group A", value: 400 },
  //  { name: "Group B", value: 300 },
  //  { name: "Group C", value: 300 },
  //  { name: "Group D", value: 200 },
  //  { name: "Group E", value: 100 },
  //];
  type FormattedData = {
    name: string;
    value: number;
  };
  const formattedData: FormattedData[] = [];
  data?.forEach((item) => {
    formattedData.push({ name: item.service, value: item.count });
  });
  return (
    <ResponsiveContainer height={250} width="100%">
      <PieChart width={700} height={250}>
        <Pie
          data={formattedData}
          innerRadius={90}
          outerRadius={110}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
