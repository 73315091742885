import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { history } from "&store/store";
import { getCurrentUser } from "&config/getCurrentUser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RootState } from "&store/store";
import { homeActions } from "./home.slice";
import { loginActions } from "&features/login/login.slice";
import { reportsActions } from "&features/reports/reports.slice";
import CarriersBigIcon from "&assets/icons/carriers-big";
import MerchantsBigIcon from "&assets/icons/merchants-big";
import ServicesBigIcon from "&assets/icons/services-big";
import SubscriptionsBigIcon from "&assets/icons/subscriptions-big";
import DatePickerComponent from "&styled/form/date-picker";
import { SubmitButton } from "&styled/button/button.component";
import CustomChart from "./purchaseChart";
import SummaryCard from "./summaryCards";

type ReduxProps = ConnectedProps<typeof connector>;

const HomeComponent = (props: ReduxProps) => {
  const {
    isLoggedIn,
    token,
    state,
    filterDashboardSummary,
    getDashboardSummary,
    filterSalesSummary,
    getDashboardSummaryCount,
  } = props;
  const [serviceDate, setServiceDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [revenueDate, setRevenueDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const user = getCurrentUser(token);
  const { clients, oneTimePurchase, services, operators, users, roles } = state.summary.cardsSummary;
  const { subscriptionSummary, salesSummary } = state.summary;

  const handleStartDate = (platform: "service" | "revenue", val) => {
    if (platform === "service") {
      setServiceDate((prev) => ({
        ...prev,
        startDate: val,
      }));
    } else {
      setRevenueDate((prev) => ({
        ...prev,
        startDate: val,
      }));
    }
  };
  const handleEndDate = (platform: "service" | "revenue", val) => {
    if (platform === "service") {
      setServiceDate((prev) => ({
        ...prev,
        endDate: val,
      }));
    } else {
      setRevenueDate((prev) => ({
        ...prev,
        endDate: val,
      }));
    }
  };
  const onFilter = async () => {
    await filterDashboardSummary(serviceDate);
  };
  const onSalesFilter = async () => {
    await filterSalesSummary(revenueDate);
  };

  const isWaleeUser = () => {
    return user!.userTypeId === "Walee";
  };

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/login");
    }
    getDashboardSummaryCount({
      platform: "subscriptions",
    })
      .then()
      .catch();
    getDashboardSummaryCount({
      platform: "services",
    })
      .then()
      .catch();
    getDashboardSummaryCount({
      platform: "oneTimePurchase",
    })
      .then()
      .catch();
    if (isWaleeUser()) {
      getDashboardSummaryCount({
        platform: "clients",
      })
        .then()
        .catch();
      getDashboardSummaryCount({
        platform: "operators",
      })
        .then()
        .catch();
    } else {
      getDashboardSummaryCount({
        platform: "roles",
      })
        .then()
        .catch();
      getDashboardSummaryCount({
        platform: "users",
      })
        .then()
        .catch();
    }
    getDashboardSummary().then().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box height={'80vh'} overflow={'auto'} sx={{
      scrollbarWidth: 'thin',
      scrollbarColor: '#6631F7 #f1f1f1',
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#6631F7',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#551DF1',
      },
      scrollBehavior: 'smooth',
    }}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard
            label={isWaleeUser() ? "Carriers" : "Users"}
            value={isWaleeUser() ? operators : users}
            icon={CarriersBigIcon}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard label={"One-Time Purchase"} value={oneTimePurchase} icon={SubscriptionsBigIcon} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard
            label={isWaleeUser() ? "Merchants" : "Roles"}
            value={isWaleeUser() ? clients : roles}
            icon={MerchantsBigIcon}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard label={"Services"} value={services} icon={ServicesBigIcon} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Service Share */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "2rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}> Services Share </Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                <DatePickerComponent
                  label="Date From"
                  value={serviceDate?.startDate}
                  onChange={(val) => {
                    handleStartDate("service", val);
                  }}
                />
                <DatePickerComponent
                  label="Date To"
                  value={serviceDate?.endDate}
                  onChange={(val) => {
                    handleEndDate("service", val);
                  }}
                />
                <SubmitButton title="Filter" handlePress={onFilter} />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <CustomChart data={subscriptionSummary} />
                  </Box>
                  {/* One Time Purchase table */}
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Transactions</TableCell>
                        <TableCell>Service Share (%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriptionSummary.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.service}
                          </TableCell>
                          <TableCell>{row.count}</TableCell>
                          <TableCell>{row.percentage?.toFixed(2)} %</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Revenue Share */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "2rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}> Revenue Share </Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                <DatePickerComponent
                  label="Date From"
                  value={revenueDate?.startDate}
                  onChange={(val) => {
                    handleStartDate("revenue", val);
                  }}
                />
                <DatePickerComponent
                  label="Date To"
                  value={revenueDate?.endDate}
                  onChange={(val) => {
                    handleEndDate("revenue", val);
                  }}
                />
                <SubmitButton title="Filter" handlePress={onSalesFilter} />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <CustomChart data={salesSummary} />
                  </Box>
                  {/*Sales Table*/}

                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Transactions</TableCell>
                        <TableCell>{user!.userTypeId === "Walee" ? "Amount" : "Merchant Amount"}</TableCell>
                        <TableCell>Currency</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesSummary.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.service}
                          </TableCell>
                          <TableCell>{row.count}</TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.currency}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
  token: state.login.token,
  state: state.home,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  setType: reportsActions.setType,
  logout: loginActions.reset,
  getDashboardSummary: homeActions.getDashboardSummary,
  filterDashboardSummary: homeActions.filterDashboardSummary,
  filterSalesSummary: homeActions.filterSalesSummary,
  getDashboardSummaryCount: homeActions.getDashboardSummaryCount,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const HomeComponentRedux = connector(HomeComponent);

export { HomeComponentRedux as HomeComponent };
