import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { storesActions } from "&features/stores/stores.slice";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";

type ReduxProps = ConnectedProps<typeof connector>;

const StoresFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getStore, addStore, storeId } = props;

  const [data, setData] = useState({
    _id: "",
    name: "",
    epStoreId: "",
    epStoreKey: "",
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short account name.")
      .max(50, "Too long account name")
      .required("Please provide valid account name"),
    epStoreId: Yup.string().required(),
    epStoreKey: Yup.string().required(),
  });

  const fetchStore = useCallback(
    async (id) => {
      try {
        const { payload } = await getStore(id);
        const store = { ...payload };
        setData(store);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getStore]
  );

  useEffect(() => {
    const id = storeId;
    if (id === "new") return;
    fetchStore(id);
  }, [storeId, fetchStore]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    if (data._id) {
      // Edit Not available right now
    } else {
      const { payload } = await addStore(vals);
      if (payload) {
        history.push("/stores");
      }
    }
  };

  return (
    <>
      <PageHeader title="Stores Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      placeHolder={"Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextInput
                      placeHolder={"EP Store Id"}
                      required={false}
                      value={formik.values?.epStoreId}
                      handleTextChange={formik.handleChange("epStoreId")}
                      hasError={!!formik.errors.epStoreId}
                      errorMessage={formik.errors.epStoreId as string}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      placeHolder={"EP Store Key"}
                      required={false}
                      value={formik.values?.epStoreKey}
                      handleTextChange={formik.handleChange("epStoreKey")}
                      hasError={!!formik.errors.epStoreKey}
                      errorMessage={formik.errors.epStoreKey as string}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  storeId: ownProps.match.params.storeId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getStore: storesActions.getStore,
  addStore: storesActions.addStore,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const StoreFormComponentRedux = connector(StoresFormComponent);

export { StoreFormComponentRedux as StoresFormComponent };
