import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";

import { FawryCheckout } from "./fawryCheckout.type";

const url = process.env.REACT_APP_MENA_URL;
/**
 * Initial state object
 */
const initialState: FawryCheckout = {};

const getSessionInfo = createAsyncThunk(
  "checkout/getSessionInfoCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/checkout/get/service?sessionId=${arg}`;
    
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname))
      );
      console.log("Success response from session info :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutVia3D = createAsyncThunk(
  "checkout/checkoutCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/3D-secure/checkout`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body)
      );
      console.log("Success response from checkout :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutViaQr = createAsyncThunk(
  "checkout/checkoutViaQrCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/e-wallet/via-qr`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body)
      );
      console.log("Success response from checkout :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutViaR2p = createAsyncThunk(
  "checkout/checkoutViaR2pCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/e-wallet/via-r-2-p`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body)
      );
      console.log("Success response from checkout via r 2 p:", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkoutViaFawryRef = createAsyncThunk(
  "checkout/checkoutViaFawryRefCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/referance-number`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body)
      );
      console.log("Success response from checkout via referance-number :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const checkStatus = createAsyncThunk(
  "checkout/checkStatusCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/payment-status`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body)
      );
      console.log("Success response from payment status :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const updateStatus = createAsyncThunk(
  "checkout/checkoutCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/3D-secure/update-status`;
      delete body.provider;
     
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(url!.concat(pathname), body)
      );
      console.log("Success response from checkout :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);


const fawryCheckoutSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "fawryCheckout",

  /**
   * Initial state object
   */
  initialState: initialState,

  
  reducers: {
    setFawryCheckout: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

});

/**
 * Reducers are exported so they could be added to store
 */
export const fawryCheckoutReducer = fawryCheckoutSlice.reducer;

export const fawryCheckoutActions = { ...fawryCheckoutSlice.actions,updateStatus, getSessionInfo,checkoutVia3D, checkoutViaQr, checkStatus, checkoutViaR2p,checkoutViaFawryRef };
