import * as React from "react";

export default function TotalRevenueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g
        id="Group_6013"
        data-name="Group 6013"
        transform="translate(-265 -145)"
      >
        <g id="Group_5670" data-name="Group 5670" transform="translate(0 9)">
          <circle
            id="Ellipse_1751"
            data-name="Ellipse 1751"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(265 136)"
            fill="#f2eeff"
          />
        </g>
        <g
          id="Group_6008"
          data-name="Group 6008"
          transform="translate(-2485.511 -1269.969)"
        >
          <path
            id="Path_22581"
            data-name="Path 22581"
            d="M2807.2,1547.669a5.035,5.035,0,0,1,5.034-4.96h3.009a1.048,1.048,0,0,1,1.047,1.048,1.049,1.049,0,0,0,1.048,1.048h5.324a1.808,1.808,0,1,1,0,3.616h-4.851"
            transform="translate(-35.989 -97.19)"
            fill="none"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
          <path
            id="Path_22582"
            data-name="Path 22582"
            d="M2842.76,1607.517l.031-.026.659-.56a5.257,5.257,0,0,1,3.118-1.251"
            transform="translate(-66.351 -150.957)"
            fill="none"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
          <path
            id="Path_22583"
            data-name="Path 22583"
            d="M2889.455,1534.611l5.115-5.512a1.584,1.584,0,0,1,2.406,2.055l-5.479,7a4.437,4.437,0,0,1-3.494,1.7h-4.693"
            transform="translate(-100.974 -85.138)"
            fill="none"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_6294"
            data-name="Rectangle 6294"
            width="10.951"
            height="5.678"
            rx="2.839"
            transform="matrix(0.654, 0.757, -0.757, 0.654, 2769.982, 1449.057)"
            fill="none"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
          <g
            id="Group_6007"
            data-name="Group 6007"
            transform="translate(2771.514 1428.88)"
          >
            <g
              id="Group_6006"
              data-name="Group 6006"
              transform="translate(7.118 3.802)"
            >
              <path
                id="Path_22584"
                data-name="Path 22584"
                d="M2863.256,1469.564h-3.54a1.746,1.746,0,0,0-1.747,1.746v.323a1.746,1.746,0,0,0,1.747,1.746h1.793a1.746,1.746,0,0,1,1.747,1.747v.4a1.746,1.746,0,0,1-1.747,1.747h-3.54"
                transform="translate(-2857.97 -1467.419)"
                fill="none"
                stroke="#6631f7"
                strokeWidth="1.5"
              />
              <path
                id="Path_22585"
                data-name="Path 22585"
                d="M2876.056,1466.883v-12"
                transform="translate(-2873.413 -1454.888)"
                fill="none"
                stroke="#6631f7"
                strokeWidth="1.5"
              />
            </g>
            <path
              id="Path_22586"
              data-name="Path 22586"
              d="M2822.885,1447.616a9.765,9.765,0,1,0-10.5-1.83"
              transform="translate(-2809.275 -1428.88)"
              fill="none"
              stroke="#6631f7"
              strokeWidth="1.5"
            />
          </g>
          <circle
            id="Ellipse_2183"
            data-name="Ellipse 2183"
            cx="0.35"
            cy="0.35"
            r="0.35"
            transform="translate(2780.926 1454.363)"
            fill="#004194"
            stroke="#6631f7"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
