import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { rolesActions } from "&features/roles/roles.slice";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import { getCurrentUser } from "&config/getCurrentUser";
type ReduxProps = ConnectedProps<typeof connector>;

const RoleFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getRole, addRole, editRole, token, roleId } = props;
  const user: any = getCurrentUser(token);

  const [data, setData] = useState({
    _id: "",
    name: "",
    description: "",
    userTypeId: "",
    permissions: {
      Dashboard: { viewAll: false, viewUserGraphs: false },
      Collection: { viewAll: false },
      Disbursement: { viewAll: false },
      Users: {
        viewAll: false,
        addUsers: false,
        editUsers: false,
        activeDeactivateUser: false,
      },
      Roles: {
        viewAll: false,
        addRoles: false,
        editRoles: false,
        activateDeactivateRole: false,
      },
      UserLogs: { viewAll: false },
      CsvJobs: { viewAll: false },
      BlacklistManagement: {
        viewAll: false,
        createBlacklist: false,
        editBlacklist: false,
      },
      CarrierManagement: {
        viewAll: false,
        createAccounts: false,
        editAccounts: false,
        activateDeactivateCarrier: false,
      },
      MerchentManagement: {
        viewAll: false,
        createAccounts: false,
        editAccounts: false,
        activateDeactivateMerchent: false,
      },
      StoresManagement: {
        viewAll: false,
        createAccounts: false,
        editAccounts: false,
      },
      OperatorManagement: {
        viewAll: false,
        createPrefix: false,
        uploadPrefix: false,
        editPrefix: false,
        deletePrefix: false,
      },
      PackagesManagement: {
        viewAll: false,
        createPackage: false,
        editPackage: false,
      },
      Subscription: { viewAll: false },
      Services: { viewAll: false },
      SubscriptionLogs: { viewAll: false },
      OneTimePurchase: { viewAll: false },
      Blacklist: {
        viewAll: false,
        addBlacklistNumber: false,
        addBulkBlacklistNumbers: false,
        editBlacklistNumber: false,
        deleteBlacklistNumber: false,
      },
      Reports: {
        viewAll: false,
        generateReport: false,
        addEditDelete: false,
      },
      ProductManagement: {
        viewAll: false,
        viewProducts: false,
        addEditDeleteProducts: false,
        createProducts: false,
        createCategory: false,
        addEditDeleteCategories: false,
        viewCategories: false,
      },
      Settlements: { viewAll: false, addSettlement: false },
      LoanRequest: { viewAll: false, viewLoanAccounts: false },
      CallCenter: { viewAll: false },
    },
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short account name.")
      .max(50, "Too long account name")
      .required("Please provide valid account name"),
    desciption: Yup.string().optional().max(150, "Too long description. Max char allowed is 150"),
  });

  const fetchRole = useCallback(
    async (id) => {
      try {
        const { payload: role } = await getRole(id);

        setData({
          _id: role._id,
          name: role.name,
          description: role.description,
          userTypeId: role.userTypeId,
          permissions: {
            Dashboard: role.permissions.Dashboard,
            Collection: role.permissions.Collection,
            Disbursement: role.permissions.Disbursement,
            Users: role.permissions.Users,
            Roles: role.permissions.Roles,
            Services: role.permissions.Services || { viewAll: false },
            UserLogs: role.permissions.UserLogs,
            CsvJobs: role.permissions.CsvJobs,
            CarrierManagement: role.permissions.CarrierManagement,
            BlacklistManagement: role.permissions.BlacklistManagement,
            MerchentManagement: role.permissions.MerchentManagement,
            StoresManagement: role?.permissions?.StoresManagement ?? {
              viewAll: false,
              createAccounts: false,
              editAccounts: false,
            },
            OperatorManagement: role.permissions.OperatorManagement,
            PackagesManagement: role.permissions.PackagesManagement,
            Subscription: role.permissions.Subscription,
            SubscriptionLogs: role.permissions.SubscriptionLogs,
            OneTimePurchase: role.permissions.OneTimePurchase,
            Blacklist: role.permissions.Blacklist,
            Reports: role.permissions.Reports,
            ProductManagement: role.permissions.ProductManagement,
            Settlements: role.permissions.Settlements,
            LoanRequest: role.permissions.LoanRequest,
            CallCenter: role.permissions.CallCenter,
          },
        });
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getRole]
  );

  useEffect(() => {
    const id = roleId;
    if (id === "new") return;
    fetchRole(id);
  }, [fetchRole, roleId]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    if (!vals.userTypeId) {
      vals["userTypeId"] = user.userTypeId;
    }
    if (data._id) {
      await editRole(vals);

      history.push("/roles");
    } else {
      await addRole(vals);

      history.push("/roles");
    }
  };

  return (
    <>
      <PageHeader title="Role Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: "80vh",
          overflow: "auto",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} xs={12}>
                    <TextInput
                      placeHolder={"Role Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Description"}
                      value={formik.values.description}
                      handleTextChange={formik.handleChange("description")}
                      hasError={!!formik.errors.description}
                      errorMessage={formik.errors.description as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Box
                    sx={{
                      font: "normal normal 600 16px/20px Poppins",
                      marginY: "2rem",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    Permissions
                  </Box>
                  {/*Dashboard Permissions*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Dashboard</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Dashboard.viewAll}
                          onChange={formik.handleChange("permissions.Dashboard.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View User Statistics"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Dashboard.viewUserGraphs}
                          onChange={formik.handleChange("permissions.Dashboard.viewUserGraphs")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View User Graphs"
                      sx={{ color: "#3C3C3C" }}
                    />
                  </Box>
                  {/*Collection Dashboard Permissions*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Collection Dashboard</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Collection?.viewAll}
                          onChange={formik.handleChange("permissions.Collection.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View Collection Dashboard"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>
                  {/*Disbursement Dashboard Permissions*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Disbursement Dashboard</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Disbursement?.viewAll}
                          onChange={formik.handleChange("permissions.Disbursement.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View Disbursement Dashboard"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>
                  {/*Users Permissions*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Users</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Users.viewAll}
                          onChange={formik.handleChange("permissions.Users.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Users"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Users.addUsers}
                          onChange={formik.handleChange("permissions.Users.addUsers")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Add Users"
                      sx={{ color: "#3C3C3C" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Users.editUsers}
                          onChange={formik.handleChange("permissions.Users.editUsers")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Edit Users"
                      sx={{ color: "#3C3C3C" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Users.activeDeactivateUser}
                          onChange={formik.handleChange("permissions.Users.activeDeactivateUser")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Activate/Deactivate Users"
                      sx={{ color: "#3C3C3C" }}
                    />
                  </Box>

                  {/*Roles Permissions*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Roles</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Roles.viewAll}
                          onChange={formik.handleChange("permissions.Roles.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Roles"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Roles.addRoles}
                          onChange={formik.handleChange("permissions.Roles.addRoles")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Add Roles"
                      sx={{ color: "#3C3C3C" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Roles.editRoles}
                          onChange={formik.handleChange("permissions.Roles.editRoles")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Edit Roles"
                      sx={{ color: "#3C3C3C" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Roles.activateDeactivateRole}
                          onChange={formik.handleChange("permissions.Roles.activateDeactivateRole")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Activate/Deactivate Roles"
                      sx={{ color: "#3C3C3C" }}
                    />
                  </Box>

                  {/*Users Log*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>User Logs</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.UserLogs.viewAll}
                          onChange={formik.handleChange("permissions.UserLogs.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View User Logs"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>

                  {/* Blacklist Management */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Blacklist Management</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.BlacklistManagement?.viewAll}
                            onChange={formik.handleChange("permissions.BlacklistManagement.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Blacklists"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.BlacklistManagement?.createBlacklist}
                            onChange={formik.handleChange("permissions.BlacklistManagement.createBlacklist")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Create Blacklist"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.BlacklistManagement?.editBlacklist}
                            onChange={formik.handleChange("permissions.BlacklistManagement.editBlacklist")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Blacklist"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Carrier Management */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Carrier Management</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.CarrierManagement.viewAll}
                            onChange={formik.handleChange("permissions.CarrierManagement.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.CarrierManagement.createAccounts}
                            onChange={formik.handleChange("permissions.CarrierManagement.createAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Create Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.CarrierManagement.editAccounts}
                            onChange={formik.handleChange("permissions.CarrierManagement.editAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.CarrierManagement.activateDeactivateCarrier}
                            onChange={formik.handleChange("permissions.CarrierManagement.activateDeactivateCarrier")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Activate/Deactivate Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}

                  {/* Merchant Management */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Merchant Management</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.MerchentManagement.viewAll}
                            onChange={formik.handleChange("permissions.MerchentManagement.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.MerchentManagement.createAccounts}
                            onChange={formik.handleChange("permissions.MerchentManagement.createAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Create Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.MerchentManagement.editAccounts}
                            onChange={formik.handleChange("permissions.MerchentManagement.editAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.MerchentManagement.activateDeactivateMerchent}
                            onChange={formik.handleChange("permissions.MerchentManagement.activateDeactivateMerchent")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Activate/Deactivate Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Stores Management */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Stores Management</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.StoresManagement.viewAll}
                            onChange={formik.handleChange("permissions.StoresManagement.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.StoresManagement.createAccounts}
                            onChange={formik.handleChange("permissions.StoresManagement.createAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Create Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.StoresManagement.editAccounts}
                            onChange={formik.handleChange("permissions.StoresManagement.editAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Packages Management */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Packages Management</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.PackagesManagement?.viewAll}
                            onChange={formik.handleChange("permissions.PackagesManagement.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Packages"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.PackagesManagement?.createPackage}
                            onChange={formik.handleChange("permissions.PackagesManagement.createPackage")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Create Package"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.PackagesManagement?.editPackage}
                            onChange={formik.handleChange("permissions.PackagesManagement.editPackage")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Package"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Subscription */}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Subscription</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Subscription.viewAll}
                          onChange={formik.handleChange("permissions.Subscription.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Subscriptions"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>
                  {/* Services */}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Services</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Services.viewAll}
                          onChange={formik.handleChange("permissions.Services.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Services"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>

                  {/* Subscription Logs*/}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Subscription Logs</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.SubscriptionLogs.viewAll}
                          onChange={formik.handleChange("permissions.SubscriptionLogs.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Subscriptions"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>

                  {/* One-TimePurchase */}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>One-Time Purchase</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.OneTimePurchase.viewAll}
                          onChange={formik.handleChange("permissions.OneTimePurchase.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All One-Time Purchase Transactions"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>
                  {/* Blacklist */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Blacklist</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Blacklist.viewAll}
                            onChange={formik.handleChange("permissions.Blacklist.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Blacklist"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Blacklist.addBlacklistNumber}
                            onChange={formik.handleChange("permissions.Blacklist.addBlacklistNumber")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Add Blacklist Number"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Blacklist.editBlacklistNumber}
                            onChange={formik.handleChange("permissions.Blacklist.editBlacklistNumber")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Edit Blacklist Number"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Blacklist.deleteBlacklistNumber}
                            onChange={formik.handleChange("permissions.Blacklist.deleteBlacklistNumber")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Delete Blacklist Number"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}

                  {/* Reports */}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Reports</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Reports.viewAll}
                          onChange={formik.handleChange("permissions.Reports.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Reports"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.Reports.generateReport}
                          onChange={formik.handleChange("permissions.Reports.generateReport")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Generate Report"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>
                  {/* Product Management */}
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Product Management</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.ProductManagement?.viewAll}
                          onChange={formik.handleChange("permissions.ProductManagement.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All Product Management"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.ProductManagement?.viewProducts}
                          onChange={formik.handleChange("permissions.ProductManagement.viewProducts")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View Products"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.ProductManagement?.createProducts}
                          onChange={formik.handleChange("permissions.ProductManagement.createProducts")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Create Product"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.ProductManagement?.viewCategories}
                          onChange={formik.handleChange("permissions.ProductManagement.viewCategories")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View Categories"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>

                  {/* Settlements */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Settlements</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Settlements.viewAll}
                            onChange={formik.handleChange("permissions.Settlements.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Settlements"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.Settlements.addSettlement}
                            onChange={formik.handleChange("permissions.Settlements.addSettlement")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Add Settlements"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Loan Request */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Loan Request</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.LoanRequest?.viewAll}
                            onChange={formik.handleChange("permissions.LoanRequest.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Loan Requests"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.LoanRequest?.viewLoanAccounts}
                            onChange={formik.handleChange("permissions.LoanRequest.viewLoanAccounts")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All Loan Accounts"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}
                  {/* Callcenter */}
                  {user.userTypeId === "Walee" && (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        padding: "1rem",
                        marginX: "1rem",
                        width: "70%",
                      }}
                    >
                      <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>Callcenter</Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.permissions.CallCenter?.viewAll}
                            onChange={formik.handleChange("permissions.CallCenter.viewAll")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="View All"
                        sx={{
                          font: "normal normal normal 12px/18px Poppins",
                          color: "#3C3C3C",
                        }}
                      />
                    </Box>
                  )}

                  {/* CSV Jobs */}

                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      padding: "1rem",
                      marginX: "1rem",
                      width: "70%",
                    }}
                  >
                    <Box sx={{ font: "normal normal 600 12px/18px Poppins" }}>CSV Jobs</Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.permissions.CsvJobs?.viewAll}
                          onChange={formik.handleChange("permissions.CsvJobs.viewAll")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="View All"
                      sx={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#3C3C3C",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "70%",
                      display: "flex",
                      justifyContent: "center",
                      marginY: "1.5rem",
                    }}
                  >
                    <Box sx={{ width: "30%" }}>
                      <SubmitButton
                        title="Save"
                        handlePress={() => {
                          formik.handleSubmit();
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  roleId: ownProps.match.params.roleId,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getRole: rolesActions.getRole,
  addRole: rolesActions.addRole,
  editRole: rolesActions.editRole,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const RoleFormComponentRedux = connector(RoleFormComponent);

export { RoleFormComponentRedux as RoleFormComponent };
