import React from "react";
import Box from "@mui/material/Box";
import MerchantLogo from "../../../images/merchant.png";

let pkFormatter = new Intl.NumberFormat("en-PK", {
  style: "currency",
  currency: "PKR",
});

const MerchantInfoComponent = ({ balanceSummary }) => {
  return (
    <Box
      sx={{
        width: "100%",

        padding: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        marginTop: "1rem",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img src={MerchantLogo} alt="merchant-logo" />
        <Box
          sx={{
            color: "#111111",
            font: "normal normal 600 18px/27px Poppins",
          }}
        >
          {balanceSummary?.merchantName}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRight: "1px solid #DCDCDC",
            paddingRight: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Box
            sx={{
              color: "#111111",
              font: "normal normal medium 12px/18px Poppins",
            }}
          >
            Total Transactions Disbursed
          </Box>
          <Box
            sx={{
              color: "#6631F7",
              font: "normal normal 600 16px/25px Poppins",
            }}
          >
            {balanceSummary?.totalTransactions}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingTop: "1rem",
          }}
        >
          <Box
            sx={{
              color: "#111111",
              font: "normal normal medium 12px/18px Poppins",
            }}
          >
            Total Amount Disbursed
          </Box>
          <Box
            sx={{
              color: "#6631F7",
              font: "normal normal 600 16px/25px Poppins",
            }}
          >
            {pkFormatter.format(balanceSummary?.totalTransactionsAmount)}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "#F6F2FF",
            borderRadius: "5px",
            padding: "1rem",
            width: "200px",
          }}
        >
          <Box
            sx={{
              color: "#111111",
              font: "normal normal medium 12px/18px Poppins",
            }}
          >
            Available Balance
          </Box>
          <Box
            sx={{
              color: "#6631F7",
              font: "normal normal 600 18px/27px Poppins",
            }}
          >
            {pkFormatter.format(balanceSummary?.availableBalance)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { MerchantInfoComponent };
