import React, { useState } from "react";

// MUI
import CheckIcon from "@mui/icons-material/Check";
import { Box, Fab, Grid, Modal } from "@mui/material";

// Components
import CustomDatePicker from "&styled/date-picker";

interface Props {
  open: boolean
  handleClose: any
  onConfirm: Function
}

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};

export default function CustomDateRangeModal({
  open,
  handleClose,
  onConfirm,
}: Props) {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-modal"
      aria-describedby="dialog-modal"
    >
      <Box sx={style}>
        {/* Header */}
        <Box sx={{ marginBottom: 3, fontSize: "1.5rem", fontWeight: "bold" }}>
          {"Custom Range"}
        </Box>

        {/* Content */}
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item md={5} xs={12}>
            <CustomDatePicker 
                value={dateFrom}
                label="From"
                onChange={(val) => setDateFrom(new Date(val))}
            />
           
          </Grid>
          <Grid item md={5} xs={12}>
            <CustomDatePicker
              value={dateTo}
              label="To"
              onChange={(val) => setDateTo(new Date(val))}
            
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Fab
                color="primary"
                aria-label="apply"
                size="medium"
                onClick={() => onConfirm(dateFrom, dateTo)}
              >
                <CheckIcon/>
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
