import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "&store/store";
import { useHistory } from "react-router-dom";
import { loginActions } from "&features/login/login.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const LogoutComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { logout } = props;

  useEffect(() => {
    console.log("Logout>>>");
    logout();
    history.push("/login");
  }, [history, logout]);

  return <div />;
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  logout: loginActions.reset,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const LogoutComponentRedux = connector(LogoutComponent);

export { LogoutComponentRedux as LogoutComponent };
