import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import EditIcon from "&assets/icons/edit";
import HistoryIcon from "&assets/icons/history";
import DeleteIcon from "&assets/icons/delete";
import { RootState } from "&store/store";
import {
  warningAlert,
  successAlert,
  errorAlert,
} from "&config/swalGenerator";
import { carriersActions } from "./carriers.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const CarriersComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getCarriers, deleteCarrier, toggleCarrierStatus } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Account Name",
      headerClassName: "header-styles",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "type",
      headerName: "Account Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => renderBtnToggle(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getCarriers();
    })();
  }, [getCarriers]);

  const toggleStatus = async (row) => {
    try {
      const params = {
        status: row.status === "Active" ? "Inactive" : "Active",
        name: row.name,
        _id: row._id,
      };
      await toggleCarrierStatus(params);
    } catch (ex) {
      alert("Something went wrong");
      console.log(ex);
    }
  };

  const onDelete = async (event, id: string) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deleteCarrier(id);

        successAlert("Deleted", "Carrier has been deleted successfully!");
      } catch (ex) {
        errorAlert();
      }
    }
  };

  const renderBtnToggle = (row) => {
    return (
      <Box
        sx={{
          backgroundColor: row.status === "Active" ? "#ECECEC" : "#D6FFD9",
          color: row.status === "Active" ? "#727272" : "#0FA01A",
          width: "85px",
          textAlign: "center",
          borderRadius: "5px",
          padding: "5px",
          fontSize: "12px",
          lineHeight: "18px",
          cursor: "pointer",
        }}
        onClick={() => toggleStatus(row)}
      >
        {row.status === "Active" ? "Deactivate" : "Activate"}
      </Box>
    );
  };

  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 2 }}>
        {renderBtnEdit(row)}
        {renderBtnHistory(row)}
        {/*{renderBtnDelete(row)}*/}
      </Box>
    );
  };
  /*const renderBtnDelete = (row) => {
    return (
      <FabButton
        label="Delete"
        color="secondary"
        onClick={(e: React.MouseEvent) => onDelete(e, row._id)}
      >
        <DeleteIcon />
      </FabButton>
    );
  };*/
  const renderBtnHistory = (row) => {
    return (
      <FabButton
        label="History"
        color="secondary"
        onClick={() => history.push(`/carriers/history/${row._id}`)}
      >
        <HistoryIcon />
      </FabButton>
    );
  };
  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/carriers/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };
  return (
    <>
      <PageHeader
        title="Carriers"
        onAdd={() => history.push("/carriers/form/new")}
        addText="Create Carrier"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.carriers,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getCarriers: carriersActions.getCarriers,
  deleteCarrier: carriersActions.deleteCarrier,
  toggleCarrierStatus: carriersActions.toggleCarrierStatus,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CarriersComponentRedux = connector(CarriersComponent);

export { CarriersComponentRedux as CarriersComponent };
