import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import SelectComponent from "&styled/form/select";
import { OutlinedButton, SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import { loanAccountBalanceDetailActions } from "../loanAccountBalanceDetail.slice";
import { merchantsActions } from "&features/merchants/merchants.slice";
import ConfirmationModal from "../confirmationModal";
import { getCurrentUser } from "&config/getCurrentUser";
import { formatter } from "&config/currencyFormatter";

const currencies = [
  { label: "PKR", value: "PKR" },
  { label: "USD", value: "USD" },
];

const paymentMethods = [
  { label: "IBFT", value: "IBFT" },
  { label: "Cash", value: "CASH" },
  { label: "Settlement", value: "SETTLEMENT" },
  { label: "USD Deposit", value: "USD-DEPOSIT" },
];

type ReduxProps = ConnectedProps<typeof connector>;

const TransactionFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    getLoanAccountDetail,
    addLoanAccountDetail,
    editLoanAccountDetail,
    getMerchants,
    loanTransactionId,
    token,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [merchants, setMerchants] = useState<any[]>([]);
  const [data, setData] = useState({
    _id: "",
    merchantName: "",
    userTypeId: "",
    currency: "",
    paymentMethod: "",
    totalAmount: "", // Number
    totalBilledAmount: "0", // Number
    exchangeRate: "", // Number
    waleeCommission: "", // Number
    sbpCharges: "", // Number
    serviceSalesTax: "", // Number
    withHoldingCharges: "", // Number
  });

  const user = getCurrentUser(token);

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const validation = Yup.object().shape({
    userTypeId: Yup.string().required("Please select a merchant"),
    paymentMethod: Yup.string().required("Please provide valid payment method"),
    currency: Yup.string().required("Please provide currency"),
    totalAmount: Yup.number().min(0).required("Please provide amount"),
    waleeCommission: Yup.number()
      .min(0)
      .max(99)
      .required("Please provide Walee commission"),
    sbpCharges: Yup.number()
      .min(0)
      .max(99)
      .required("Please provide SBP commission"),
    serviceSalesTax: Yup.number()
      .min(0)
      .max(99)
      .required("Please provide Service sales tax"),
    withHoldingCharges: Yup.number()
      .min(0)
      .max(99)
      .required("Please provide withholding tax"),
    exchangeRate: Yup.number().when("currency", {
      is: "USD",
      then: Yup.number().min(0).required("Please provide exchange rate"),
    }),
  });

  const fetchLoanTransaction = useCallback(
    async (id) => {
      try {
        const { payload } = await getLoanAccountDetail(id);
        const loanTransaction = { ...payload };
        if (loanTransaction.currency === "PKR") {
          loanTransaction.exchangeRate = "";
        }
        delete loanTransaction.createdDate;
        delete loanTransaction.__v;
        setData(loanTransaction);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getLoanAccountDetail]
  );

  useEffect(() => {
    if (user?.userTypeId !== "Walee")
      return history.push("/loan-accounts-balance");
  });

  useEffect(() => {
    const id = loanTransactionId;

    (async () => {
      const { payload } = await getMerchants();
      const merchantList: any[] = [];
      payload.forEach((m) => {
        merchantList.push({ label: m.name, value: m._id });
      });
      setMerchants(merchantList);
    })();
    if (id === "new") return;
    fetchLoanTransaction(id);
  }, [loanTransactionId, fetchLoanTransaction, getMerchants]);

  const handleMerchantChange = (e, formik) => {
    const val = e.target.value;
    const merchant = merchants.find((m) => m.value === val);
    formik.setFieldValue("merchantName", merchant.label);
    formik.setFieldValue("userTypeId", val);
  };

  const handleAmountChange = (e, formik) => {
    const { setFieldValue } = formik;
    setFieldValue("totalAmount", e);
    setFieldValue("totalBilledAmount", e);
  };

  const handleSubmit = async (vals) => {
    setModalOpen(true);
  };

  const handleConfirm = async (vals) => {
    if (data._id) {
      await editLoanAccountDetail(vals);
      history.push("/loan-accounts-balance");
    } else {
      delete vals._id;
      await addLoanAccountDetail(vals);
      history.push("/loan-accounts-balance");
    }
  };

  return (
    <>
      <PageHeader title="New Transaction" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card sx={{ height: "83vh", overflow: "auto" }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={6} sm={12}>
                    <SelectComponent
                      value={formik.values.userTypeId}
                      onSelect={(e) => handleMerchantChange(e, formik)}
                      menuItems={merchants}
                      placeHolder="Select Merchant"
                      hasError={!!formik.errors.userTypeId}
                      errorMessage={formik.errors.userTypeId as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <SelectComponent
                      value={formik.values.currency}
                      onSelect={formik.handleChange("currency")}
                      menuItems={currencies}
                      placeHolder="Select Currency"
                      hasError={!!formik.errors.currency}
                      errorMessage={formik.errors.currency as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <SelectComponent
                      value={formik.values.paymentMethod}
                      onSelect={formik.handleChange("paymentMethod")}
                      menuItems={paymentMethods}
                      placeHolder="Select Payment Method"
                      hasError={!!formik.errors.paymentMethod}
                      errorMessage={formik.errors.paymentMethod as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <TextInput
                      placeHolder={"Amount"}
                      required={false}
                      value={formik.values.totalAmount}
                      type="number"
                      handleTextChange={(e) => handleAmountChange(e, formik)}
                      hasError={!!formik.errors.totalAmount}
                      errorMessage={formik.errors.totalAmount as string}
                    />
                  </Grid>

                  {formik.values.currency === "USD" && (
                    <>
                      <Grid item lg={6} sm={12}>
                        <TextInput
                          placeHolder={"Exchange Rate"}
                          required={false}
                          value={formik.values.exchangeRate}
                          type="number"
                          handleTextChange={formik.handleChange("exchangeRate")}
                          hasError={!!formik.errors.exchangeRate}
                          errorMessage={formik.errors.exchangeRate as string}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item lg={6} sm={12}>
                    <TextInput
                      placeHolder={"Walee Commission (%)"}
                      value={formik.values.waleeCommission}
                      type="number"
                      handleTextChange={formik.handleChange("waleeCommission")}
                      hasError={!!formik.errors.waleeCommission}
                      errorMessage={formik.errors.waleeCommission as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <TextInput
                      placeHolder={"SBP Charges (%)"}
                      required={false}
                      value={formik.values.sbpCharges}
                      type="number"
                      handleTextChange={formik.handleChange("sbpCharges")}
                      hasError={!!formik.errors.sbpCharges}
                      errorMessage={formik.errors.sbpCharges as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <TextInput
                      placeHolder={"Service Sales Tax (%)"}
                      required={false}
                      value={formik.values.serviceSalesTax}
                      type="number"
                      handleTextChange={formik.handleChange("serviceSalesTax")}
                      hasError={!!formik.errors.serviceSalesTax}
                      errorMessage={formik.errors.serviceSalesTax as string}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <TextInput
                      placeHolder={"Withholding Charges (%)"}
                      required={false}
                      value={formik.values.withHoldingCharges}
                      type="number"
                      handleTextChange={formik.handleChange(
                        "withHoldingCharges"
                      )}
                      hasError={!!formik.errors.withHoldingCharges}
                      errorMessage={formik.errors.withHoldingCharges as string}
                    />
                  </Grid>
                  {formik.values.totalBilledAmount && (
                    <Grid item lg={12} marginTop={"1.5rem"}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#F6F2FF",
                          borderRadius: "5px",
                          padding: "2rem",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#111111",
                            font: "normal normal medium 14px/25px Poppins",
                          }}
                        >
                          Total billed amount
                        </Box>
                        <Box
                          sx={{
                            color: "#6631F7",
                            font: "normal normal 600 20px/25px Poppins",
                          }}
                        >
                          {formatter("PK", "PKR").format(
                            parseFloat(formik.values.totalBilledAmount)
                          )}{" "}
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  <Grid item lg={12} marginTop={"3rem"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ width: "100px" }}>
                        <OutlinedButton
                          title="Cancel"
                          handlePress={() => history.push("/merchant-top-ups")}
                        />
                      </Box>
                      <Box sx={{ width: "100px" }}>
                        <SubmitButton
                          title="Save"
                          handlePress={() => {
                            formik.handleSubmit();
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <ConfirmationModal
                    open={modalOpen}
                    values={formik.values}
                    handleClose={handleModalClose}
                    handleExit={handleConfirm}
                  />
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  loanTransactionId: ownProps.match.params.loanTransactionId,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getLoanAccountDetail: loanAccountBalanceDetailActions.getLoanAccountDetail,
  addLoanAccountDetail: loanAccountBalanceDetailActions.addLoanAccountDetail,
  editLoanAccountDetail: loanAccountBalanceDetailActions.editLoanAccountDetail,
  getMerchants: merchantsActions.getMerchants,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const TransactionFormComponentRedux = connector(TransactionFormComponent);

export { TransactionFormComponentRedux as TransactionFormComponent };
