import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import { SubmitButton, OutlinedButton } from "&styled/button/button.component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 2,
};

export default function ConfirmationModal({
  open,
  handleClose,
  handleExit,
  values,
}) {
  const {
    _id,
    merchantName,
    totalAmount,
    userTypeId,
    currency,
    paymentMethod,
    exchangeRate,
    waleeCommission,
    sbpCharges,
    withHoldingCharges,
    serviceSalesTax,
  } = values;
  const [netAmount, setNetAmount] = useState(0);
  const [totalBilledAmount, setTotalBilledAmount] = useState(0);

  useEffect(() => {
    const grossAmount = parseFloat(totalAmount);
    const totalTax =
      +waleeCommission + +sbpCharges + +withHoldingCharges + +serviceSalesTax;

    const calculatedVal = (totalTax * grossAmount) / 100;
    const netAmount = grossAmount - calculatedVal;
    if (currency === "USD") {
      setTotalBilledAmount(netAmount * +exchangeRate);
    } else {
      setTotalBilledAmount(netAmount);
    }
    setNetAmount(netAmount);
  }, [
    waleeCommission,
    sbpCharges,
    withHoldingCharges,
    serviceSalesTax,
    totalAmount,
    currency,
    exchangeRate,
  ]);

  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  const handleConfirm = async () => {
    const params = {
      _id,
      merchantName,
      totalAmount,
      userTypeId,
      currency,
      exchangeRate,
      paymentMethod,
      waleeCommission,
      sbpCharges,
      withHoldingCharges,
      serviceSalesTax,
      totalBilledAmount,
      availableBilledAmount: totalBilledAmount,
    };
    await handleExit(params);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              color: "#2e2e2e",
              fontSize: "20px",
              fontWeight: "bold",
              marginY: "1rem",
            }}
          >
            Transaction Summary
          </Box>
          <Divider />
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Merchant</Box>
            </Grid>
            <Grid item lg={6}>
              {merchantName}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Total Amount</Box>
            </Grid>
            <Grid item lg={6}>
              {totalAmount}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Currency</Box>
            </Grid>
            <Grid item lg={6}>
              {currency}
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Walee Commission</Box>
            </Grid>
            <Grid item lg={6}>
              {waleeCommission} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>SBP Charges</Box>
            </Grid>
            <Grid item lg={6}>
              {sbpCharges} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Service Sales Tax</Box>
            </Grid>
            <Grid item lg={6}>
              {serviceSalesTax} %
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Withholding Tax</Box>
            </Grid>
            <Grid item lg={6}>
              {withHoldingCharges} %
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Grid item lg={6}>
              <Box sx={{ fontWeight: "bold" }}>Total Billed Amount</Box>
            </Grid>
            <Grid item lg={6}>
              {netAmount}
            </Grid>

            {currency === "USD" && (
              <>
                <Grid item lg={6}>
                  <Box sx={{ fontWeight: "bold" }}>Exchange Rate</Box>
                </Grid>
                <Grid item lg={6}>
                  {exchangeRate}
                </Grid>
              </>
            )}
            <Grid item lg={12}>
              <Box
                sx={{
                  backgroundColor: "rgb(237, 231, 255)",
                  width: "100%",
                  padding: "1rem",
                  borderRadius: "4px",
                }}
              >
                An amount of{" "}
                <span style={{ fontWeight: "bold" }}>
                  {pkFormatter.format(totalBilledAmount)}
                </span>{" "}
                will be transferred after deducting all the charges
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginY: "1rem",
              gap: 2,
              justifyContent: "center",
            }}
          >
            <OutlinedButton title="Confirm" handlePress={handleConfirm} />
            <SubmitButton title="Cancel" handlePress={handleClose} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
