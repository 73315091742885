import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "&assets/icons/edit";
import HistoryIcon from "&assets/icons/history";
import DeleteIcon from "&assets/icons/delete";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";
import {
  warningAlert,
  successAlert,
  infoAlert,
  errorAlert,
} from "&config/swalGenerator";

import { productsActions } from "./products.slice";

import styles from "./products.module.css";


type ReduxProps = ConnectedProps<typeof connector>;

const ProductsComponent = (props: ReduxProps) => {
 
  const history = useHistory();
  const { state, getProducts } = props;
  const { products } = state;
  const { data, current, pages, total } = products;
  const [pageSize, setPageSize] = React.useState(25);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
    rows: [],
    loading: false,
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "productPrice",
      headerName: "Price",
      renderCell:({row})=> row.attributes.productPrice,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "inventory",
      headerName: "Inventory",
      renderCell:({row})=> row.attributes.inventory,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getProducts(1);
    })();
  }, [getProducts]);

  const onPageChange = async (page) => {
    await getProducts(page + 1);
  };

  //const onDelete = async (event, id: string) => {
  //  const value = await warningAlert();
  //  if (value) {
  //    try {
  //      await deleteMerchant(id);

  //      successAlert("Deleted", "Merchant has been deleted successfully!");
  //    } catch (ex) {
  //      errorAlert();
  //    }
  //  }
  //};

 
  

 
  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {/*{renderBtnEdit(row)}*/}
        {/*{renderBtnDelete(row)}*/}
      </Box>
    );
  };
  //const renderBtnDelete = (row) => {
  //  return (
  //    <FabButton
  //      label="Delete"
  //      color="secondary"
  //      onClick={(e: React.MouseEvent) => onDelete(e, row._id)}
  //    >
  //      <DeleteIcon />
  //    </FabButton>
  //  );
  //};
  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/merchants/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  return (
    <>
    <PageHeader
      title="Products"
      onAdd={() => history.push("/products/form/new")}
      addText="Create Product"
    />
    <Box
      sx={{
        marginY: "1rem",
        borderTop: "4px solid #6631F7",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      }}
    >
      <Card>
        <CardContent>
          <div style={{ height: 700, width: "100%" }}>
           <DataGrid
                paginationMode="server"
                onPageChange={onPageChange}
                onPageSizeChange={(pageSize) =>
                  setRowsState((prev) => ({ ...prev, pageSize }))
                }
                rows={data}
                columns={columns}
                pageSize={pageSize}
                page={current - 1}
                rowCount={total}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
          </div>
        </CardContent>
      </Card>
    </Box>
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.products,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getProducts: productsActions.getProducts,
 
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ProductsComponentRedux = connector(ProductsComponent);

export { ProductsComponentRedux as ProductsComponent };
