import * as React from 'react'

export default function AmountPouchIcon(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
  <g id="Group_6016" data-name="Group 6016" transform="translate(-265 -145)">
    <g id="Group_5670" data-name="Group 5670" transform="translate(0 9)">
      <circle id="Ellipse_1751" data-name="Ellipse 1751" cx="30" cy="30" r="30" transform="translate(265 136)" fill="#f2eeff"/>
    </g>
    <g id="Group_6001" data-name="Group 6001" transform="translate(-1058.611 -827.459)">
      <path id="Path_22569" data-name="Path 22569" d="M1364.008,1051.58a11.134,11.134,0,0,0-.874,1.293l-3.2,5.546a10.6,10.6,0,0,0,9.184,15.9h2.058" transform="translate(-18.708 -55.928)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
      <path id="Path_22570" data-name="Path 22570" d="M1460.677,1051.58a10.947,10.947,0,0,1,.875,1.293l3.2,5.546a10.6,10.6,0,0,1-9.184,15.9h-2.06" transform="translate(-98.758 -55.928)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
      <rect id="Rectangle_6293" data-name="Rectangle 6293" width="19.079" height="4.009" rx="2.005" transform="translate(1344.071 991.643)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
      <path id="Path_22571" data-name="Path 22571" d="M1401.1,996.717l-1.685-2.918a1.386,1.386,0,0,1-.069-.133,1.734,1.734,0,0,1,2.364-2.248,10.883,10.883,0,0,0,9.774-.009,1.733,1.733,0,0,1,2.416,2.121,1.906,1.906,0,0,1-.13.27l-1.685,2.918" transform="translate(-52.98 -5.074)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
      <g id="Group_6000" data-name="Group 6000" transform="translate(1350.83 1000.747)">
        <path id="Path_22572" data-name="Path 22572" d="M1437.669,1103.364h-3.723a1.837,1.837,0,0,0-1.837,1.837v.339a1.837,1.837,0,0,0,1.837,1.837h1.886a1.837,1.837,0,0,1,1.837,1.837v.417a1.837,1.837,0,0,1-1.837,1.837h-3.723" transform="translate(-1432.109 -1101.108)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
        <path id="Path_22573" data-name="Path 22573" d="M1446.262,1091.881V1104.5" transform="translate(-1443.482 -1091.881)" fill="none" stroke="#6631f7" strokeWidth="1.5"/>
      </g>
      <circle id="Ellipse_2180" data-name="Ellipse 2180" cx="0.377" cy="0.377" r="0.377" transform="translate(1353.233 1018.017)" fill="#004194" stroke="#6631f7" strokeWidth="1.5"/>
    </g>
  </g>
</svg>
  )
}
