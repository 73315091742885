import * as React from 'react';

export default function ExportIcon(){
  return(
    <svg id="Iconly_Light-Outline_Logout" data-name="Iconly/Light-Outline/Logout" xmlns="http://www.w3.org/2000/svg"  width="18.537" height="18" viewBox="0 0 18.537 18">
  <defs>
    <clipPath id="clip-path">
      <path id="Clip_7" data-name="Clip 7" d="M0,0H4V6.6H0Z" fill="none"/>
    </clipPath>
  </defs>
  <g id="Logout">
    <path id="Combined_Shape" data-name="Combined Shape" d="M4,18a4,4,0,0,1-4-3.992V3.992A4,4,0,0,1,4,0H8.4a4,4,0,0,1,4,3.992v.838a.677.677,0,0,1-1.354,0V3.992A2.648,2.648,0,0,0,8.4,1.35H4A2.647,2.647,0,0,0,1.354,3.992V14.008A2.648,2.648,0,0,0,4,16.65H8.411a2.639,2.639,0,0,0,2.64-2.632V13.17a.677.677,0,0,1,1.354,0v.849A3.992,3.992,0,0,1,8.411,18Zm10.736-5.9a.674.674,0,0,1,0-.954l1.482-1.472H6.991a.675.675,0,1,1,0-1.349h9.23L14.74,6.854a.675.675,0,1,1,.955-.956l2.63,2.611a.672.672,0,0,1,.212.457v0s0,.008,0,.012,0,.011,0,.017,0,.011,0,.017,0,.008,0,.011v.005a.669.669,0,0,1-.044.208v0l0,.01,0,.006,0,.006,0,.01v0a.664.664,0,0,1-.1.157.681.681,0,0,1-.052.054L15.695,12.1a.678.678,0,0,1-.957,0Z" fill="#fff"/>
    <g id="Group_8" data-name="Group 8" transform="translate(14.541 5.702)">
      <path id="Clip_7-2" data-name="Clip 7" d="M0,0H4V6.6H0Z" transform="translate(0 0)" fill="none"/>
    </g>
  </g>
</svg>

  )
}
