import * as React from "react";
import Carret from "../images/arrow-down.png";

export default function DownCarret() {
  return (
    <img
      src={Carret}
      alt="carret-icon"
      style={{ width: "20px", height: "20px", marginRight: "0.5rem" }}
    />
  );
}
