import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { App } from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "&config/i18n";
import { store, persistor } from "./store/store";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* Persists redux store using imported persistor */}
      <PersistGate loading={null} persistor={persistor}>
        {/* Configures i18n */}
        <I18nextProvider i18n={i18n}>
          {/* App main entry, passes history for propper navigation */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,

  document.getElementById("root")
);

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
