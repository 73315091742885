import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import { blacklistsActions } from "../blacklists.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const BlacklistFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getBlacklist, addBlacklist, editBlacklist, blacklistId } = props;

  const [data, setData] = useState({
    _id: "",
    msisdn: "",

  });

  const validation = Yup.object().shape({
    msisdn: Yup.string().required("Please provide msisdn"),
    
  });

  const fetchBlacklist = useCallback(
    async (id) => {
      try {
        const { payload } = await getBlacklist(id);
        const blacklist = { ...payload };
        delete blacklist.createdDate;
        delete blacklist.__v;
        setData(blacklist);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getBlacklist]
  );

  useEffect(() => {
    const id = blacklistId;
    if (id === "new") return;
    fetchBlacklist(id);
  }, [blacklistId, fetchBlacklist]);

  // Fetch revenue info

  const handleSubmit = async (vals) => {
    if (data._id) {
      await editBlacklist(vals);

      history.push("/blacklists");
    } else {
      await addBlacklist(vals);
      history.push("/blacklists");
     
    }
  };
  
  return (
    <>
      <PageHeader title="Carrier Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                 
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"MSISDN (923xxxxxxx)"}
                      value={formik.values.msisdn}
                      handleTextChange={formik.handleChange("msisdn")}
                      hasError={!!formik.errors.msisdn}
                      errorMessage={formik.errors.msisdn as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                 
                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  blacklistId: ownProps.match.params.blacklistId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getBlacklist: blacklistsActions.getBlacklist,
  addBlacklist: blacklistsActions.addBlacklist,
  editBlacklist: blacklistsActions.editBlacklist,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const BlacklistFormComponentRedux = connector(BlacklistFormComponent);

export { BlacklistFormComponentRedux as BlacklistFormComponent };
