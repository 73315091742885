import * as React from "react";

export default function SmallFileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
    >
      <g
        id="Group_5698"
        data-name="Group 5698"
        transform="translate(-264.5 -230.5)"
      >
        <text
          id="xlc"
          transform="translate(271.272 243.364)"
          fill="#6631f7"
          fontSize="6"
          fontFamily="Poppins-Medium, Poppins"
          fontWeight="500"
        >
          <tspan x="-4" y="0">
            xlc
          </tspan>
        </text>
        <g id="file" transform="translate(265 231)">
          <path
            id="Path_22539"
            data-name="Path 22539"
            d="M10.75,2H5.5A1.5,1.5,0,0,0,4,3.5v12A1.5,1.5,0,0,0,5.5,17h9A1.5,1.5,0,0,0,16,15.5V7.25Z"
            transform="translate(-4 -2)"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_22540"
            data-name="Path 22540"
            d="M13,2V7.25h5.25"
            transform="translate(-6.25 -2)"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
