import jwtDecode from "jwt-decode";
type UserType = {
  iat: string;
  email: string;
  userTypeId: string;
  lastLogin: string;
  name: string;
  role: any;
  userName: string;
  userType: string;
  _id: string;
  accountBalance?: string;
  showBalance?: string;
};

export function getCurrentUser(token) {
  try {
    const user: UserType = jwtDecode(token);
    return user;
  } catch (ex) {
    return null;
  }
}
