import * as React from "react";

export default function EyeIcon() {
  return (
    <svg
      id="Iconly_Light-Outline_Show"
      data-name="Iconly/Light-Outline/Show"
      xmlns="http://www.w3.org/2000/svg"
      width="18.998"
      height="15.105"
      viewBox="0 0 18.998 15.105"
    >
      <g id="Show" transform="translate(0)">
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M9.5,15.106H9.5C5.567,15.1,2.038,12.383.056,7.83a.71.71,0,0,1,0-.555C2.038,2.723,5.567,0,9.5,0H9.5c3.93,0,7.458,2.723,9.44,7.275a.7.7,0,0,1,0,.555C16.96,12.383,13.431,15.1,9.5,15.106ZM1.49,7.552C3.254,11.4,6.248,13.7,9.5,13.7s6.245-2.3,8.008-6.147C15.744,3.707,12.75,1.409,9.5,1.407S3.255,3.707,1.49,7.552Zm4.293,0A3.716,3.716,0,1,1,9.5,11.221,3.7,3.7,0,0,1,5.783,7.554Zm1.425,0A2.291,2.291,0,1,0,9.5,5.292,2.278,2.278,0,0,0,7.208,7.554Z"
          transform="translate(0)"
          fill="#6631f7"
        />
      </g>
    </svg>
  );
}
