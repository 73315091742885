import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function ErrorScreen({ error, matches }) {
  return (
    <Box
      sx={{
        width: matches ? "40rem" : "280px",
        height: "55rem",
        margin: "10% auto",
      }}
    >
      <Card
        sx={{ borderRadius: "8px 8px 0px 0px", borderTop: "4px solid #6631F7" }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon sx={{ fontSize: "4rem", color: "#FF1D1D" }} />
            <Box
              sx={{
                marginY: "1.2rem",
                fontSize: matches ? "2rem" : "0.8rem",
                color: "#FF1D1D",
              }}
            >
              {error}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
