import * as React from "react";

export default function TotalNumberOfTransactionsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g
        id="Group_6015"
        data-name="Group 6015"
        transform="translate(-1009 -145)"
      >
        <g id="Group_5672" data-name="Group 5672" transform="translate(186 9)">
          <circle
            id="Ellipse_1753"
            data-name="Ellipse 1753"
            cx="30.5"
            cy="30.5"
            r="30.5"
            transform="translate(823 136)"
            fill="#f2eeff"
          />
        </g>
        <path
          id="Path_22551"
          data-name="Path 22551"
          d="M18.4,2H7.2A3.2,3.2,0,0,0,4,5.2V30.8A3.2,3.2,0,0,0,7.2,34H26.4a3.2,3.2,0,0,0,3.2-3.2V2Z"
          transform="translate(1023.2 157)"
          fill="none"
          stroke="#6631f7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <g id="dollar-sign" transform="translate(1031.583 163.956)">
          <line
            id="Line_2901"
            data-name="Line 2901"
            y2="10.123"
            transform="translate(8.748 1.483)"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_22553"
            data-name="Path 22553"
            d="M11.348,5H7.7a1.7,1.7,0,0,0,0,3.4h2.431a1.7,1.7,0,1,1,0,3.4H6"
            transform="translate(0 -1.955)"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <line
          id="Line_2924"
          data-name="Line 2924"
          x2="11"
          transform="translate(1034.5 180.5)"
          fill="none"
          stroke="#6631f7"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_2925"
          data-name="Line 2925"
          x2="11"
          transform="translate(1034.5 184.5)"
          fill="none"
          stroke="#6631f7"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
