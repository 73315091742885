import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { merchantsActions } from "&features/merchants/merchants.slice";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";

type ReduxProps = ConnectedProps<typeof connector>;

const AccountBalanceFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { updateAccountBalance, merchantId } = props;

  const validation = Yup.object().shape({
    amount: Yup.number().required("Please enter valid amount"),
    tax: Yup.number().min(0).max(99).required("Please provide valid tax"),
  });

  const initialValues = {
    amount: "",
    tax: "",
    netAmount: "",
  };
  const handleSubmit = async (vals) => {
    const params = {
      _id: merchantId,
      grossAmount: vals.amount,
      tax: vals.tax,
      netAmount: vals.netAmount
    }
    await updateAccountBalance(params);
    history.push("/merchants");
  };

  const handleTaxChange = useCallback(async(val, formik)=>{
    const { values, setFieldValue, setFieldError, setFieldTouched } = formik;
    if (!values.amount) {
      return setFieldError("amount", "Please provide amount first");
    }
    const grossAmount = parseInt(values.amount);
    if (val) {
      const calculatedVal = parseFloat(val) * grossAmount / 100;
      const netAmount = parseInt(values.amount) - calculatedVal;
      setFieldValue("netAmount", netAmount);
    } else {
      setFieldValue("netAmount", "");
    }
    await setFieldValue("tax", val);
     await setFieldTouched("tax", true)
  },[])

  return (
    <>
      <PageHeader title="Account Balance" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={true}
              onSubmit={(values: typeof initialValues) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof initialValues>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Amount"}
                      type="number"
                      value={formik.values.amount}
                      handleTextChange={formik.handleChange("amount")}
                      hasError={!!formik.errors.amount}
                      errorMessage={formik.errors.amount as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Tax"}
                      type="number"
                      value={formik.values.tax}
                      handleTextChange={(e) => handleTaxChange(e, formik)}
                      hasError={!!formik.errors.tax}
                      errorMessage={formik.errors.tax as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4}>
                    {formik.values.netAmount && (
                      <Box>Net amount : {formik.values.netAmount}</Box>
                    )}
                  </Grid>
                  <Grid item lg={8}></Grid>
                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  merchantId: ownProps.match.params.merchantId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getMerchant: merchantsActions.getMerchant,
  updateAccountBalance: merchantsActions.updateAccountBalance,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AccountBalanceFormComponentRedux = connector(AccountBalanceFormComponent);

export { AccountBalanceFormComponentRedux as AccountBalanceFormComponent };
