import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { getCurrentUser } from "&config/getCurrentUser";
import { WALEE_BASE_URL } from "&config/url";
import { Collection } from "./collection.type";

const initialState: Collection = {};
const url = process.env.REACT_APP_COLLECTION_ANALYTICS_BASE_URL;

const getFilterData = createAsyncThunk(
  "collection/getFilterDataCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const operatorPathname = `/operators/get`;
    const merchantPathname = `/merchants/get`;
    const token = getState().login.token;

    const user: any = getCurrentUser(token);

    const headers = {
      "x-auth-token": token,
    };
    const query = [
      axios.get(WALEE_BASE_URL.concat(operatorPathname), { headers }),
    ];
    if (user.userTypeId === "Walee") {
      query.unshift(
        axios.get(WALEE_BASE_URL.concat(merchantPathname), { headers })
      );
    }
    try {
      /** make api call */
      const response = await trackPromise(Promise.all(query));
      if (response.length === 2) {
        const [{ data: merchants }, { data: operators }] = response;
        const data = { merchants, operators };
        return data;
      } else {
        const [{ data: operators }] = response;
        const data = { merchants: null, operators };
        return data;
      }
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const searchCollectionAnalytics = createAsyncThunk(
  "collection/searchCollectionAnalyticsCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const revenueSummaryPathname = `/get-summary`;
   
    const token = getState().login.token;
    const searchParams = arg;
    const headers = {
      "x-auth-token": token,
    };
    const dateFrom = new Date(searchParams.dateFrom.getTime() - searchParams.dateFrom.getTimezoneOffset() * 60000);
    const dateTo = new Date(searchParams.dateTo.getTime() - searchParams.dateTo.getTimezoneOffset() * 60000);
    delete searchParams.dateFrom;
    delete searchParams.dateTo;
    searchParams.dateFrom = dateFrom;
    searchParams.dateTo = dateTo;
    try {
      /** make api call */
      const response = await trackPromise(axios.post(url!.concat(revenueSummaryPathname), searchParams, {
        headers,
      }));

      return response;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const collectionSlice = createSlice({
  name: "collection",
  initialState: initialState,
  reducers: {
    setCollection: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
});

export const collectionReducer = collectionSlice.reducer;

export const collectionActions = {
  ...collectionSlice.actions,
  getFilterData,
  searchCollectionAnalytics,
};
