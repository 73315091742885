import Swal from "sweetalert2";

export const warningAlert = () => {
  return Swal.fire({
    title: "Are you sure?",
    text: "Changes will not be revert back.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  } as any).then((result) => {
    if (result.value) {
      return result.value;
    }
  });
};

export const successAlert = (label, message) => {
  return Swal.fire(`${label}!`, message, "success");
};

export const errorAlert = () => {
  return Swal.fire("Oops...", "Something went wrong", "error");
};

export const infoAlert = (label, message) => {
  return Swal.fire(label, message, "info");
};
