import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from "@mui/material/Box";
export default function CustomDatePicker({ label, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ backgroundColor: "#fff" }}>
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );
}
