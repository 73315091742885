import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Box from '@mui/material/Box';
import { RootState } from "&store/store";
import { creditCardRedirectActions } from "../creditCardRedirect.slice";
import {servicesActions} from '&features/services/services.slice';
import Loader from "&styled/loader/loader";

type ReduxProps = ConnectedProps<typeof connector>;

const CreditCardRedirectFailedComponent = (props: ReduxProps) => {
  const {serviceId,orderId, getService, checkout} = props;
const params = {
    serviceId,
    orderId,
    status: "Failed"
}
  useEffect(() => {
(async()=>{
  // get service by sid
  const {payload} = await getService(serviceId)
  // update transaction
  await checkout(params)

  // get the redirect url
  const clientUrl = payload.portalUrl;
  window.location.href = clientUrl + `?status=SUCCESS&orderId=${orderId}`;
  return;
})()
  }, [serviceId, getService, checkout, params, orderId]);

  return (
    <Box sx={{width:"100vw", height: "100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Loader/>;
    </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
  orderId: ownProps.match.params.orderId,
 
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getServiceBySid,
  checkout: creditCardRedirectActions.checkout
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CreditCardRedirectFailedComponentRedux = connector(CreditCardRedirectFailedComponent);

export { CreditCardRedirectFailedComponentRedux as CreditCardRedirectFailedComponent };
