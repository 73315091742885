import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactComponent as AlertIcon } from "&assets/icons/alert.svg";
import { SubmitButton, OutlinedButton } from "&styled/button/button.component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 4,
};

export default function ConfirmationModal({
  open,
  handleClose,
  handleExit,
  heading,
  text,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              padding: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <AlertIcon />
            <Box
              sx={{
                color: "#2e2e2e",
                fontSize: "20px",
                fontWeight: "bold",
                marginY: "1rem",
              }}
            >
              {heading}
            </Box>
            <Box sx={{ color: "rgba(46, 46, 46, 0.7)", fontSize: "14px" }}>
              {text}
            </Box>
            <Box
              sx={{ width: "60%", display: "flex", marginY: "1rem", gap: 2 }}
            >
              <OutlinedButton title="Yes" handlePress={handleExit} />
              <SubmitButton title="No" handlePress={handleClose} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
