import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Subscriptions } from "./subscriptions.type";

/**
 * Initial state object
 */
const initialState: Subscriptions = {
  subscriptions: {
    current: 1,
    data: [],
    pages: 0,
    total: 0,
  },
};

const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptionsCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/subscriptions/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from roles :", response.data);
      dispatch(subscriptionsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const subscriptionsSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "subscriptions",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setSubscriptions: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.subscriptions = action.payload;
    },
    reset: () => initialState,
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getSubscriptions.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const subscriptionsReducer = subscriptionsSlice.reducer;

/**
 * Actions hold the same names as reducers.
 * Actions can be dispached using 'useDispacth' hook,
 * or by 'mapDispatchToProps' in the redux 'connect' function
 */
export const subscriptionsActions = {
  ...subscriptionsSlice.actions,
  getSubscriptions,
};
