import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card'
import  CardContent  from "@mui/material/CardContent";
import  Box  from "@mui/material/Box";
import { RootState } from "&store/store";
import { callCenterActions } from "./callCenter.slice";

import styles from "./callCenter.module.css";


type ReduxProps = ConnectedProps<typeof connector>;

const CallCenterComponent = (props: ReduxProps) => {
  
  const { t } = useTranslation(["callCenter"]); 

  useEffect(() => {
    // Write your side-effects here
  }, []);

  return (
    <div>Callcenter</div>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  // map your actions here ex:
  // increment : counterActions.increment
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CallCenterComponentRedux = connector(CallCenterComponent);

export { CallCenterComponentRedux as CallCenterComponent };
