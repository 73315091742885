import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
//import EditIcon from "@mui/icons-material/Edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import EditIcon from "&assets/icons/edit";
import DeleteIcon from "&assets/icons/delete";
import { RootState } from "&store/store";
import {
  warningAlert,
  successAlert,
  errorAlert,
} from "&config/swalGenerator";

import { blacklistsActions } from "./blacklists.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const BlacklistsComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getBlacklists, deleteBlacklist } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "msisdn",
      headerName: "MSISDN",
      headerClassName: "header-styles",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getBlacklists();
    })();
  }, [getBlacklists]);

  const onDelete = async (event, id: string) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deleteBlacklist(id);

        successAlert("Deleted", "Blacklist has been deleted successfully!");
      } catch (ex) {
        errorAlert();
      }
    }
  };

  const renderButtons = (row) => {
    return (
      <Box sx={{ display: "flex", gap: 2 }}>
        {renderBtnEdit(row)}
        {renderBtnDelete(row)}
      </Box>
    );
  };
  const renderBtnDelete = (row) => {
    return (
      <FabButton
        label="Delete"
        color="secondary"
        onClick={(e: React.MouseEvent) => onDelete(e, row._id)}
      >
        <DeleteIcon />
      </FabButton>
    );
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/blacklists/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };
  return(
    <>
    <PageHeader
      title="Blacklists"
      onAdd={() => history.push("/blacklists/form/new")}
      addText="Create Blacklist"
    />
    <Box
      sx={{
        marginY: "1rem",
        borderTop: "4px solid #6631F7",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      }}
    >
      <Card>
        <CardContent>
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100]}
              getRowId={(row) => row._id}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </CardContent>
      </Card>
    </Box>
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
    state: state.blacklists
});

const mapDispatchToProps = {
  getBlacklists: blacklistsActions.getBlacklists,
  deleteBlacklist: blacklistsActions.deleteBlacklist,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const BlacklistsComponentRedux = connector(BlacklistsComponent);

export { BlacklistsComponentRedux as BlacklistsComponent };
