import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import ToggleView from "&styled/toggleView/toggleView.component";
import { getCurrentUser } from "&config/getCurrentUser";
import { RootState } from "&store/store";
import { merchantTopupActions } from "./merchantTopup.slice";
import CardView from "./cardView";
import styles from './merchantTopup.module.css';

type ReduxProps = ConnectedProps<typeof connector>;

let pkFormatter = new Intl.NumberFormat("en-PK", {
  style: "currency",
  currency: "PKR",
});

const MerchantTopupComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getLoanAccounts, token } = props;
  const { data } = state;
  const [pageSize, setPageSize] = useState(25);
  const [view, setView] = useState("list");

  const user = getCurrentUser(token)
 
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  useEffect(() => {
    (async () => {
      await getLoanAccounts();
    })();
  }, [getLoanAccounts]);

  const renderButtons = (row) => {
    return (
      <Box
        sx={{
          padding: "0.3rem 0.8rem",
          color: "#6631F7",
          cursor: "pointer",
          font:"normal normal medium 12px/18px Poppins",
        }}
        onClick={() => history.push(`/loan-account-detail/${row.userTypeId}`)}
      >
        View Details
      </Box>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "merchantName",
      renderHeader:()=>(
        <Box sx={{
          fontWeight: "bold",
          fontSize: "12px",
          color:"#3C3C3C"
        }}>MERCHANT NAME</Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "availableBalance",
      renderHeader:()=>(
        <Box sx={{
          fontWeight: "bold",
          fontSize: "12px",
          color:"#3C3C3C"
        }}>AVAILABLE BALANCE</Box>
      ),
      flex: 1,
      renderCell: ({ row }) => pkFormatter.format(row.availableBalance),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalTransactions",
      renderHeader:()=>(
        <Box sx={{
          fontWeight: "bold",
          fontSize: "12px",
          color:"#3C3C3C"
        }}>TOTAL TRANSACTIONS DISBURSED</Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalTransactionsAmount",
      renderHeader:()=>(
        <Box sx={{
          fontWeight: "bold",
          fontSize: "12px",
          color:"#3C3C3C"
        }}>TOTAL AMOUNT DISBURSED</Box>
      ),
      flex: 1,
      renderCell: ({ row }) =>
        pkFormatter.format(row.totalTransactionsAmount || 0),
      headerAlign: "left",
      align: "left",
    },

    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <>
    <PageHeader
      title="Merchant Top Up"
      onAdd={user?.userTypeId === "Walee" ? () => history.push("/loan-account-detail/form/new") : null}
      addText={user?.userTypeId === "Walee" ? "Create Transaction": null}
    />
          {/*<Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <ToggleView view={view} handleChange={handleChange} />
          </Box>*/}
    <Box
      sx={{
        marginY: "1rem",
        borderTop: "4px solid #6631F7",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        "& .purple": {
          backgroundColor: "#F6F2FF",
          color: "#6631F7",
        },
      }}
    >

      {view === "module" && <CardView data={data} />}

      {view === "list" && (
        <Card>
          <CardContent>
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                getCellClassName={(params: GridCellParams<number>) => {
                  if (
                    params.field === "availableBalance"
                  ) {
                    return "purple";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          </CardContent>
        </Card>
      )}
    </Box>
  </>
  )
};

const mapStateToProps = (state: RootState) => ({
  state: state.loanAccounts,
  token: state.login.token,
});

const mapDispatchToProps = {
  getLoanAccounts: merchantTopupActions.getLoanAccounts,
};


const connector = connect(mapStateToProps, mapDispatchToProps);
const MerchantTopupComponentRedux = connector(MerchantTopupComponent);

export { MerchantTopupComponentRedux as MerchantTopupComponent };
