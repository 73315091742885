import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import qs from 'query-string';
import Box from '@mui/material/Box';
import { RootState } from "&store/store";
import { fawryCheckoutActions } from "../fawryCheckout.slice";
import {servicesActions} from '&features/services/services.slice';
import Loader from "&styled/loader/loader";

type ReduxProps = ConnectedProps<typeof connector>;

const MenaRedirectComponent = (props: ReduxProps) => {
  const {serviceId, orderId, queryParams, getService, updateStatus} = props;
  const parsed = qs.parse(queryParams);
  parsed.serviceId = serviceId;
  parsed.paymentMethod = "CC";
  parsed.orderId = orderId;

  useEffect(() => {
(async()=>{
  // get service by sid
  const {payload} = await getService(serviceId)
  // update transaction
  await updateStatus(parsed)
  // get the redirect url
  const clientUrl = payload.portalUrl;
  // modify the response and redirect to client
  let redirectStr = "";
  if(parsed.orderStatus === "PAID"){
    redirectStr = `?status=SUCCESS&orderId=${orderId}&amount=${parsed.paymentAmount}`
  }else{
    redirectStr = `?status=FAILED&orderId=${orderId}`
  }
if(clientUrl){
   window.location.href = clientUrl + redirectStr;
}

})()
  }, [queryParams, serviceId, getService,parsed, updateStatus, orderId]);

  return (
    <Box sx={{width:"100vw", height: "100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
    <Loader/>;
  </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
  orderId: ownProps.match.params.orderId,
  queryParams: ownProps.location.search
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getServiceBySid,
  updateStatus: fawryCheckoutActions.updateStatus
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const MenaRedirectComponentRedux = connector(MenaRedirectComponent);

export { MenaRedirectComponentRedux as MenaRedirectComponent };
