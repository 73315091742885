import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";

import { Stores } from "./stores.type";
import { WALEE_BASE_URL } from "&config/url";

const initialState: Stores = {
  data: [],
};

const getStores = createAsyncThunk(
  "stores/getStoresCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/stores/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(axios.get(WALEE_BASE_URL.concat(pathname), { headers }));
      dispatch(storesActions.setData(response.data));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getStore = createAsyncThunk(
  "stores/getStoreCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/stores/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(axios.get(WALEE_BASE_URL.concat(pathname), { headers }));
      dispatch(storesActions.setData(response.data));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addStore = createAsyncThunk(
  "stores/addStoreCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/stores/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(axios.post(WALEE_BASE_URL.concat(pathname), body, { headers }));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const storesSlice = createSlice({
  name: "stores",
  initialState: initialState,
  reducers: {
    setStores: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getStores.pending, (state, action) => {});
    builder.addCase(getStores.fulfilled, (state, action) => {});
    builder.addCase(getStores.rejected, (state, action) => {});
  },
});

export const storesReducer = storesSlice.reducer;

export const storesActions = {
  ...storesSlice.actions,
  getStores,
  getStore,
  addStore,
};
