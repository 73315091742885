import { createSlice } from "@reduxjs/toolkit";

import { Language } from "./language.type";

const initialState: Language = {
  language: "en",
};

const languageSlice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {
    setLanguage: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },
});

export const languageReducer = languageSlice.reducer;
export const languageActions = { ...languageSlice.actions };
