import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";


import PageHeader from "&styled/page-header";
import CardContent from "@mui/material/CardContent";
import { getCurrentUser } from "&config/getCurrentUser";
import { errorAlert } from "&config/swalGenerator";

import { RootState } from "&store/store";
import { storesActions } from "./stores.slice";


type ReduxProps = ConnectedProps<typeof connector>;

const StoresComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getStores, token } = props;
  const user: any = getCurrentUser(token);
  const viewStorePermissions = user?.role?.permissions?.StoresManagement?.viewAll
  const { data } = state;
  const [pageSize, setPageSize] = useState(25);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epStoreId",
      headerName: "EP Store Id",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "epStoreKey",
      headerName: "EP Store Key",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    if(viewStorePermissions) {
      getStores().then().catch((error)=>{
        errorAlert();
      });
    }
  }, [getStores, viewStorePermissions]);

  return <>
  <PageHeader title="Stores" onAdd={() => history.push("/stores/form/new")} addText="Create Store" />
  <Box
    sx={{
      marginY: "1rem",
      borderTop: "4px solid #6631F7",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    }}
  >
    <Card>
      <CardContent sx={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100, 500]}
          getRowId={(row) => row._id}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </CardContent>
    </Card>
  </Box>
</>;
};


const mapStateToProps = (state: RootState) => ({
  state: state.stores,
  token: state.login.token,
});


const mapDispatchToProps = {
  getStores: storesActions.getStores,
};


const connector = connect(mapStateToProps, mapDispatchToProps);
const StoresComponentRedux = connector(StoresComponent);

export { StoresComponentRedux as StoresComponent };
