import * as React from "react";

export default function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.828"
      height="10.828"
      viewBox="0 0 10.828 10.828"
    >
      <g id="x" transform="translate(-4.451 -4.451)">
        <line
          id="Line_2561"
          data-name="Line 2561"
          x1="8"
          y2="8"
          transform="translate(5.865 5.865)"
          fill="none"
          stroke="#e53030"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_2562"
          data-name="Line 2562"
          x2="8"
          y2="8"
          transform="translate(5.865 5.865)"
          fill="none"
          stroke="#e53030"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
